import React, { useState } from "react";
import { Link, NavLink } from 'react-router-dom';
import "./../App.css";
import Logo from './Logo';
import { TextAds } from './AdsComponent';

const Header = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <header className="flex flex-wrap items-center justify-start">
      <NavLogo />
      <NavMenu />
      <div style={{padding: '0.5em',minHeight:'120px'}}>
      <p>
          <Link className="tags" to="/tags/年轻萝莉/">年轻萝莉</Link>
          <Link className="tags" to="/tags/百合拉拉/">百合拉拉</Link>
          <Link className="tags" to="/tags/巨乳肥臀/">巨乳肥臀</Link>
          <Link className="tags" to="/tags/老汉推车/">老汉推车</Link>
          <Link className="tags" to="/tags/自慰喷水/">自慰喷水</Link>
          <Link className="tags" to="/tags/口交深喉/">口交深喉</Link>
          <Link className="tags" to="/tags/情趣丝袜/">情趣丝袜</Link>
        {isExpanded && (
          <span>
            <Link className="tags" to="/tags/调教虐待/"  id="">调教虐待</Link>
            <Link className="tags" to="/tags/颜射吞精/" id="">颜射吞精</Link>
            <Link className="tags" to="/tags/家庭乱伦/">家庭乱伦</Link>
            <Link className="tags" to="/tags/多人群P/" >多人群P</Link>
            <Link className="tags" to="/tags/户外啪啪/" >户外啪啪</Link>
            <Link className="tags" to="/tags/奇葩怪癖/">奇葩怪癖</Link>
            <Link className="tags" to="/tags/制服诱惑/">制服诱惑</Link>
            <Link className="tags" to="/tags/舔逼品玉/">舔逼品玉</Link>
            <Link className="tags" to="/tags/情趣内衣/">情趣内衣</Link>
            <Link className="tags" to="/tags/推油乳交/">推油乳交</Link>
            <Link className="tags" to="/tags/极品女神/">极品女神</Link>
            <Link className="tags" to="/tags/69互舔/">69互舔</Link>
            <Link className="tags" to="/tags/抽插特写/">抽插特写</Link>
            <Link className="tags" to="/tags/自拍偷拍/">自拍偷拍</Link>
            <Link className="tags" to="/tags/足交恋足/">足交恋足</Link>
            <Link className="tags" to="/tags/奸夫淫妇/">奸夫淫妇</Link>
            <Link className="tags" to="/tags/打打飞机/">打打飞机</Link>
            <Link className="tags" to="/tags/美穴白虎/">美穴白虎</Link>
            <Link className="tags" to="/tags/名模空姐/">名模空姐</Link>
            <Link className="tags" to="/tags/网红主播/">网红主播</Link>
            <Link className="tags" to="/tags/大庭广众/">大庭广众</Link>
            <Link className="tags" to="/tags/厕所偷拍/">厕所偷拍</Link>
            <Link className="tags" to="/tags/成人玩具/">成人玩具</Link>
            <Link className="tags" to="/tags/教师学生/">教师学生</Link>
            <Link className="tags" to="/tags/刺激车震/">刺激车震</Link>
            <Link className="tags" to="/tags/初夜开苞/">初夜开苞</Link>
            <Link className="tags" to="/tags/">查看更多</Link>
          </span>
        )}
        <button className="read-more-btn" onClick={toggleExpansion}>
          {isExpanded ? "收起" : "展开更多"}
        </button>
      </p>
    </div>
      <div className="announce bg-tb w-full p-4 md:p-6 text-center mb-4">
        发任意内容回复最新地址 <a href="mailto:8x8x@zhaohuimail.com?subject=最新地址找回"><b> 8x8x@zhaohuimail.com </b></a><br></br><br></br><a href="https://mjv81xw.com" targer="_blank"><b>点此进入最新地址发布页（加入书签防迷路）</b></a>
      </div>
      <TextAds />
    </header>
  );
};

const NavLogo = () => (
  <div className="w-full flex items-center justify-left">
    <Logo />
    <div className="slogan h-12 flex items-center justify-right text-xs">
      <NavLink className="domainslogn_palyer" href="/">8x8x.com - 欢迎来到新版网站</NavLink>      
    </div>
  </div>
);

const NavMenu = () => (
  <nav className="w-full my-2">
    <ul className="w-full text-sm nav h-8 flex items-center justify-center text-center text-tw">
        <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-sc text-bg hover:bg-sc hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tb hover:text-tw'} to="/">首页</NavLink></li>
        <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-sc text-bg hover:bg-sc hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tb hover:text-tw'} to="/h/大陆/">大陆</NavLink></li>
        <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-sc text-bg hover:bg-sc hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tb hover:text-tw'} to="/h/日韩/">日韩</NavLink></li>
        <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-sc text-bg hover:bg-sc hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tb hover:text-tw'} to="/h/欧美/">欧美</NavLink></li>
        <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-sc text-bg hover:bg-sc hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tb hover:text-tw'} to="/h/三级/">三级</NavLink></li>
        <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-sc text-bg hover:bg-sc hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tb hover:text-tw'} to="/h/动漫/">动漫</NavLink></li>
        <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-sc text-bg hover:bg-sc hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tb hover:text-tw'} to="/h/色漫/">色漫</NavLink></li>
        <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-sc text-bg hover:bg-sc hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tb hover:text-tw'} to="/h/图片/">图片</NavLink></li>
        <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-sc text-bg hover:bg-sc hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tb hover:text-tw'} to="/h/小说/">小说</NavLink></li>
        <li className="w-14 h-full"><NavLink className={({ isActive }) => isActive ? 'flex h-full items-center justify-center bg-sc text-bg hover:bg-sc hover:text-tb' : 'flex h-full items-center justify-center hover:bg-tb hover:text-tw'} to="/search/">搜索</NavLink></li>
    </ul>
  </nav>
);

export default Header;
